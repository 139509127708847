import React from 'react';
import './App.css';
import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './AuthProvider';
import Routes from './Routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import Sidebar from './Views/Sidebar';
import { ThemeProvider } from 'styled-components';
import { theme } from './Theme/theme';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <AuthProvider>
          <div style={{ display: 'flex', minHeight: '100vh' }}>
            <Sidebar />
            <main style={{ flex: 1, padding: '20px', overflow: 'auto' }}>
              <Routes />
            </main>
          </div>
        </AuthProvider>
      </BrowserRouter>    
    </ThemeProvider>
  );
}

export default App;
