export const theme = {
  colors: {
    primary: '#5529F2',    // Lila - huvudfärg för viktiga element
    secondary: '#F2C3A7',  // Beige - mjuk accentfärg
    accent: '#F24405',     // Orangeröd - för call-to-actions och varningar
    
    // Utökad palett
    primaryLight: '#6B44F3',
    primaryDark: '#4420C2',
    secondaryLight: '#F5D4BE',
    secondaryDark: '#E5B094',
    
    // Neutrala färger
    background: '#FFFFFF',
    backgroundAlt: '#F5F5F5',
    surface: '#F8F9FA',
    text: '#1A1A1A',
    textLight: '#6C757D',
    border: '#DEE2E6',
    
    // Funktionella färger
    success: '#28A745',
    error: '#F24405',
    warning: '#FFC107',
    info: '#5529F2'
  },
  
  shadows: {
    sm: '0 1px 3px rgba(0,0,0,0.12)',
    md: '0 4px 6px rgba(0,0,0,0.1)',
    lg: '0 10px 15px rgba(0,0,0,0.1)'
  },
  
  spacing: {
    xs: '4px',
    sm: '8px',
    md: '16px',
    lg: '24px',
    xl: '32px'
  },
  
  borderRadius: {
    sm: '4px',
    md: '8px',
    lg: '12px',
    xl: '16px'
  }
}; 