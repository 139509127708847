import { createContext, ReactNode, useEffect, useState } from 'react'
import AuthenticationService from './AuthenticationService'
import { useLocation, useNavigate } from 'react-router-dom';

type Props = {
  children?: ReactNode;
}

const AuthContext = createContext<boolean>(false)

const AuthProvider = ({children}: Props) => {

  const lastAuthStatus: boolean = JSON.parse(localStorage.getItem('authenticated') || 'false');

  const authenticationService = new AuthenticationService();
  const location = useLocation();
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(lastAuthStatus);
  
  
  useEffect(() => {
    console.log("AutProvider useEffect --current location: " + location.pathname + " origin: " + location.state?.from?.pathName)
    authenticationService.onAuthStateChanged((loggedIn: boolean) => {
      console.log("AuthContext state change - logged in: " + loggedIn)
      const alreadyAuthenticated = authenticated;
      setAuthenticated(loggedIn); // Its important to set the state before checking the location because the state change will trigger a redirect
      const atLoginOrSignupPage = location.pathname === '/login' || location.pathname === '/signup';
      localStorage.setItem('authenticated', loggedIn.toString());
      console.log("Already authenticated: " + alreadyAuthenticated);
      console.log("At login or signup page: " + atLoginOrSignupPage);
      console.log("Logged in: " + loggedIn);
      console.log("Location: " + location.pathname);
      if ((!alreadyAuthenticated || atLoginOrSignupPage) && loggedIn) {
        const origin = location.state?.from?.pathName || '/';
        console.log("Navigating to: " + origin);
        navigate(origin);
      }
    });
  }, []);

  return (
    <AuthContext.Provider value={authenticated}>
      {children}
    </AuthContext.Provider>
  )
}

export {  AuthContext, AuthProvider }