import { get } from "http";
import { UserRegistration } from "../Repositories/UserRepository";

class SignupService {
  private registration: UserRegistration = {
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    club: ""
  };
  static instance = new SignupService();

  updateRegistration(updater: (registration: UserRegistration) => UserRegistration) {
    this.registration = updater(this.registration);
  }

  getRegistration() {
    return this.registration;
  }

  getCredentials() {
    return { email: this.registration.email, password: this.registration.password };
  }

  getUserInfo() {
    return {
      firstName: this.registration.firstName,
      lastName: this.registration.lastName,
      club: this.registration.club
    };
  }

  reset() {
    this.registration = {
      email: "",
      password: "",
      firstName: "",
      lastName: "",
      club: ""
    };
  }
}

export default SignupService;