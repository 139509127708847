import React from 'react';
import { Modal, Button, ListGroup } from 'react-bootstrap';
import { Registration } from './Registration';

interface RegistrationModalProps {
    show: boolean;
    onHide: () => void;
    registration: Registration;
}

const RegistrationModal: React.FC<RegistrationModalProps> = ({ show, onHide, registration }) => {
    function formatDate(registrationDate: { seconds: number; nanoseconds: number }): string {
        const date = new Date(registrationDate.seconds * 1000);
        return date.toLocaleDateString('sv-SE', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    }

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Header closeButton>
                <Modal.Title>Lopp {registration.raceNumber}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup variant="flush">
                    <ListGroup.Item>
                        <strong>Registrerad:</strong> {formatDate(registration.registrationDate)}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <strong>Ansvarig:</strong> {registration.responsibleName}
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <strong>Klubbar:</strong>
                        <ul className="mb-0">
                            {registration.clubs.map((club) => (
                                <li key={club.id}>{club.name}</li>
                            ))}
                        </ul>
                    </ListGroup.Item>
                    <ListGroup.Item>
                        <strong>Roddare:</strong>
                        <ul className="mb-0">
                            {registration.crew.map((rower) => (
                                <li key={rower.rowerId}>{rower.name}</li>
                            ))}
                        </ul>
                    </ListGroup.Item>
                    {registration.lateRegistration && (
                        <ListGroup.Item className="text-warning">
                            <strong>Efteranmälan</strong>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Stäng
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RegistrationModal; 