import AuthenticationService from "../AuthenticationService";
import UserRepository, { UserRegistration } from "../Repositories/UserRepository";
import SignupService from "../Services/RegistrationService";

class SignupUseCase {
  userRepository = UserRepository.instance;
  auth = AuthenticationService.instance;
  static instance = new SignupUseCase();

  async execute(): Promise<void> {
    await this.auth.signup();
    this.userRepository.registerUser();
  }
}

export default SignupUseCase;