import React, { useState, useEffect } from 'react';
import { Sidebar as ProSidebar, Menu, MenuItem } from 'react-pro-sidebar';
import { useNavigate, useLocation } from 'react-router-dom';
import LogoutUseCase from '../Use Cases/LogoutUseCase';
import { FaBars, FaHome, FaUsers, FaSignOutAlt } from 'react-icons/fa';
import { theme } from '../Theme/theme';
import styled from 'styled-components';
import { ThemeProvider } from 'styled-components';

const SidebarContainer = styled.div`
  position: sticky;
  top: 0;
  height: 100vh;
  background-color: ${props => props.theme.colors.backgroundAlt};
  border-radius: ${props => props.theme.borderRadius.md};
  box-shadow: ${props => props.theme.shadows.md};
  margin: 0;
  padding: 0;
`;

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(window.innerWidth <= 768);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const handleResize = () => {
      setCollapsed(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <SidebarContainer>
        <ProSidebar 
          collapsed={collapsed}
          style={{ 
            height: '100%',
            border: 'none',
            background: theme.colors.primary,
            margin: 0,
            padding: 0,
          }}
        >
          <div
            style={{
              padding: theme.spacing.lg,
              display: 'flex',
              alignItems: 'center',
              justifyContent: collapsed ? 'center' : 'space-between',
              borderBottom: `1px solid ${theme.colors.primaryLight}`,
            }}
          >
            {!collapsed && (
              <div style={{ 
                color: theme.colors.background,
                fontWeight: 600,
                fontSize: '18px'
              }}>
                Tranodd Regatta
              </div>
            )}
            <FaBars
              style={{ 
                cursor: 'pointer',
                color: theme.colors.background,
                fontSize: '20px'
              }}
              onClick={() => setCollapsed(!collapsed)}
            />
          </div>

          <Menu
            menuItemStyles={{
              button: ({ level, active }) => ({
                backgroundColor: active ? theme.colors.primaryDark : 'transparent',
                color: theme.colors.background,
                padding: `${theme.spacing.md} ${theme.spacing.lg}`,
                margin: `${theme.spacing.xs} ${theme.spacing.sm}`,
                borderRadius: theme.borderRadius.md,
                '&:hover': {
                  backgroundColor: theme.colors.primaryLight,
                },
              }),
            }}
          >
            <MenuItem 
              icon={<FaHome />}
              onClick={() => navigate('/')}
              active={location.pathname === '/'}
            >
              Hem
            </MenuItem>
            <MenuItem 
              icon={<FaUsers />}
              onClick={() => navigate('/rowers')}
              active={location.pathname === '/rowers'}
            >
              Roddare
            </MenuItem>
            <MenuItem 
              icon={<FaSignOutAlt />}
              onClick={() => {
                LogoutUseCase.instance.execute();
                navigate('/login');
              }}
              style={{ 
                marginTop: 'auto', 
                color: theme.colors.secondary,
              }}
            >
              Logga ut
            </MenuItem>
          </Menu>
        </ProSidebar>
      </SidebarContainer>
    </ThemeProvider>
  );
};

export default Sidebar;