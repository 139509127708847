import React from "react";
import { Stack } from "react-bootstrap";

export type Registration = {
    id: string;
    raceId: string;
    lateRegistration: boolean;
    registrationDate: {
        seconds: number;
        nanoseconds: number;
    };
    raceNumber: string;
    responsibleId: string;
    responsibleName: string;
    clubs: {
        id: string;
        name: string;
    }[];
    crew: {
        rowerId: string;
        name: string;
    }[];
}

interface RegistrationViewProps {
    registration: Registration;
    onClick?: () => void;
}

const RegistrationView: React.FC<RegistrationViewProps> = ({ registration, onClick }) => {
    function formatDate(registrationDate: { seconds: number; nanoseconds: number }): string {
        const date = new Date(registrationDate.seconds * 1000);
        const year = date.getFullYear().toString().substr(-2);
        const month = ('0' + (date.getMonth() + 1)).slice(-2);
        const day = ('0' + date.getDate()).slice(-2);
        return `${year}-${month}-${day}`;
    }
    
    return (
        <Stack onClick={onClick} style={{ cursor: 'pointer' }}>
            <Stack direction="horizontal" style={{justifyContent: "space-between"}}>
                <p style={{margin: 0}}>{registration.raceNumber}</p>
                <p style={{margin: 0}}>{formatDate(registration.registrationDate)}</p>
            </Stack>
            <Stack>
                {registration.crew.map((rower) => (
                    <p key={rower.rowerId} style={{margin: 0}}>{rower.name}</p>
                ))}
            </Stack>
        </Stack>
    );
}

export default RegistrationView;