import PersistenceService from "../PersistanceService";
import SignupService from "../Services/RegistrationService";

export type User = {
    id: string;
    firstName: string;
    lastName: string;
    club: string;
}

export type UserRegistration = {
    email: string;
    password: string;
    firstName: string;
    lastName: string;
    club: string;
}

class UserRepository {
    private persistanceService = PersistenceService.instance;
    static instance: UserRepository = new UserRepository();

    async getAllUsers() {
        return this.persistanceService.getDocuments<User>("accounts")
            .then((users) => {
            console.log("Users fetched successfully. Users: ", users);
            return users;
            });
    }

    async getUserById(userId: string) {
        return this.persistanceService.getDocument<User>("accounts", userId)
            .then((user) => {
            console.log("User fetched successfully. User: ", user);
            return user;
            });
    }

    async registerUser() {
        console.log("Creating user...");
        const user: Omit<User, "id"> = SignupService.instance.getUserInfo();
        return this.persistanceService.saveDocument("accounts", user);
    }

    async addUser(user: Omit<User, "id">): Promise<void> {
        return this.persistanceService.saveDocument("accounts", user);
    }

    async addRower(rower: Omit<User, "id">): Promise<void> {
        console.log('Sparar roddare:', rower); // Debug logging
        try {
            return await this.persistanceService.saveDocument("accounts", {
                ...rower,
                roles: ['rower'], // Lägg till rower-roll
                active: true
            });
        } catch (error) {
            console.error('Fel vid sparande av roddare:', error);
            throw error;
        }
    }
}

export default UserRepository;