import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Stack, ListGroup, Alert } from 'react-bootstrap';
import UserRepository, { User } from '../Repositories/UserRepository';
import RegistrationService from '../Services/RegistrationService';
import { UserRegistration } from '../Repositories/UserRepository';
import styled from 'styled-components';
import { theme } from '../Theme/theme';

const PageContainer = styled(Container)`
  max-width: 600px;
  padding: ${theme.spacing.lg};
`;

const PageTitle = styled.h2`
  color: ${theme.colors.text};
  margin-bottom: ${theme.spacing.md};
  font-weight: 600;
`;

const Card = styled.div`
  background: ${theme.colors.background};
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing.lg};
  box-shadow: ${theme.shadows.md};
  margin-bottom: ${theme.spacing.lg};
`;

const StyledListGroup = styled(ListGroup)`
  .list-group-item {
    background: ${theme.colors.background};
    border: 1px solid ${theme.colors.border};
    margin-bottom: ${theme.spacing.sm};
    padding: ${theme.spacing.md};
    border-radius: ${theme.borderRadius.md};
    color: ${theme.colors.text};
    
    &:hover {
      background: ${theme.colors.backgroundAlt};
      transform: translateY(-2px);
      transition: all 0.2s ease;
    }
  }
`;

const ActionButton = styled(Button)`
  background-color: ${theme.colors.primary};
  border-color: ${theme.colors.primary};
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  border-radius: ${theme.borderRadius.md};
  
  &:hover {
    background-color: ${theme.colors.primaryDark};
    border-color: ${theme.colors.primaryDark};
  }
`;

const RowersScreen: React.FC = () => {
    const [user, setUser] = useState<Omit<User, "id">>({
        firstName: '',
        lastName: '',
        club: '',
    });
    const [rowers, setRowers] = useState<User[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    
    const userRepository = UserRepository.instance;

    // Hämta alla roddare när komponenten laddas
    useEffect(() => {
        loadRowers();
    }, []);

    const loadRowers = async () => {
        try {
            setLoading(true);
            const fetchedRowers = await userRepository.getAllUsers();
            setRowers(fetchedRowers);
            setError(null);
        } catch (err) {
            setError('Kunde inte hämta roddare');
            console.error(err);
        } finally {
            setLoading(false);
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.target;
        setUser(prevUser => ({
            ...prevUser,
            [name]: value,
        }));
    };

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault();
        try {
            setLoading(true);
            console.log('Försöker lägga till roddare:', user);
            
            await userRepository.addRower(user);
            console.log('Roddare tillagd framgångsrikt');
            
            // Rensa formuläret
            setUser({
                firstName: '',
                lastName: '',
                club: '',
            });
            // Uppdatera listan
            await loadRowers();
            setError(null);
        } catch (err) {
            console.error('Detaljerat fel:', err);
            setError(`Kunde inte lägga till roddare: ${err instanceof Error ? err.message : 'Okänt fel'}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <PageContainer>
            <Stack gap={4}>
                {/* Formulär för att lägga till roddare */}
                <Card>
                    <PageTitle>Lägg till roddare</PageTitle>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Stack gap={3}>
                            <Form.Group controlId="firstName">
                                <Form.Label>Förnamn:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="firstName"
                                    value={user.firstName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="lastName">
                                <Form.Label>Efternamn:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="lastName"
                                    value={user.lastName}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                            <Form.Group controlId="club">
                                <Form.Label>Klubb:</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="club"
                                    value={user.club}
                                    onChange={handleInputChange}
                                    required
                                />
                            </Form.Group>
                            <ActionButton 
                                className="w-100" 
                                variant="primary" 
                                type="submit"
                                disabled={loading}
                            >
                                {loading ? 'Sparar...' : 'Lägg till'}
                            </ActionButton>
                        </Stack>
                    </Form>
                </Card>

                {/* Lista över roddare */}
                <Card>
                    <PageTitle>Roddare</PageTitle>
                    {loading && <p>Laddar roddare...</p>}
                    <StyledListGroup>
                        {rowers.map((rower) => (
                            <ListGroup.Item key={rower.id}>
                                <div className="d-flex justify-content-between align-items-center">
                                    <div>
                                        <strong>{rower.firstName} {rower.lastName}</strong>
                                        <div className="text-muted">{rower.club}</div>
                                    </div>
                                </div>
                            </ListGroup.Item>
                        ))}
                    </StyledListGroup>
                </Card>
            </Stack>
        </PageContainer>
    );
};

export default RowersScreen;