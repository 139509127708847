import React, { useEffect, useState } from 'react';
import RegattaRegistrationScreen from './RegattaRegistration';
import PersistanceService from '../PersistanceService';
import RegistrationView, { Registration } from '../Views/Registration';
import { Container, ListGroup, Stack, Button } from 'react-bootstrap';
import RegistrationsRepository from '../Repositories/RegistrationsRepository';
import AuthenticationService from '../AuthenticationService';
import RegisterView from '../Views/Register';
import RaceRepository from '../Repositories/RaceRepository';
import UserRepository from '../Repositories/UserRepository';
import { Link } from 'react-router-dom';
import RegistrationModal from '../Views/RegistrationModal';
import { theme } from '../Theme/theme';
import styled from 'styled-components';

const PageContainer = styled(Container)`
  max-width: 600px;
  padding: ${theme.spacing.lg};
`;

const PageTitle = styled.h2`
  color: ${theme.colors.text};
  margin-bottom: ${theme.spacing.md};
  font-weight: 600;
`;

const SectionTitle = styled.h6`
  color: ${theme.colors.textLight};
  font-weight: 600;
  margin-bottom: ${theme.spacing.md};
  text-transform: uppercase;
  letter-spacing: 0.5px;
`;

const Card = styled.div`
  background: ${theme.colors.background};
  border: 1px solid ${theme.colors.border};
  border-radius: ${theme.borderRadius.lg};
  padding: ${theme.spacing.lg};
  box-shadow: ${theme.shadows.md};
  margin-bottom: ${theme.spacing.lg};
`;

const StyledListGroup = styled(ListGroup)`
  .list-group-item {
    background: ${theme.colors.background};
    border: 1px solid ${theme.colors.border};
    margin-bottom: ${theme.spacing.sm};
    padding: ${theme.spacing.md};
    border-radius: ${theme.borderRadius.md};
    color: ${theme.colors.text};
    
    &:hover {
      background: ${theme.colors.backgroundAlt};
      transform: translateY(-2px);
      transition: all 0.2s ease;
      cursor: pointer;
    }
  }
`;

const ActionButton = styled(Button)`
  background-color: ${theme.colors.primary};
  border-color: ${theme.colors.primary};
  padding: ${theme.spacing.sm} ${theme.spacing.md};
  border-radius: ${theme.borderRadius.md};
  
  &:hover {
    background-color: ${theme.colors.primaryDark};
    border-color: ${theme.colors.primaryDark};
  }
`;

const HomeScreen: React.FC = () => {
    const persistanceService = new PersistanceService();
    const [registrations, setRegistrations] = useState<Registration[]>([]);
    const registrationsRepository = RegistrationsRepository.instance;

    const [raceOptions, setRaceOptions] = React.useState<{ value: string; label: string; }[]>([]);
    const [rowerOptions, setRowerOptions] = React.useState<{ value: string; label: string; }[]>([]);

    const [loading, setLoading] = React.useState(true);
    const [selectedRegistration, setSelectedRegistration] = useState<Registration | null>(null);

    const authenticationService = AuthenticationService.instance;

    useEffect(() => {
        console.log("HomeScreen useEffect")
        authenticationService.onAuthStateChanged((loggedIn: boolean) => {
            if (loggedIn) {
                registrationsRepository.getRegistrations().then((registrations) => {
                    setRegistrations(registrations);
                    setLoading(false);
                }).catch((error) => {
                    console.error(error);
                });

                RaceRepository.instance.getRaces()
                .then((races) => {
                    const raceOptions = races.map((race) => ({
                        value: race.id,
                        label: race.description
                    }))
                    setRaceOptions(raceOptions);
                })

                UserRepository.instance.getAllUsers()
                .then((users) => {
                    const rowerOptions = users.map((user) => ({
                        value: `${user.id}`,
                        label: `${user.firstName} ${user.lastName}`
                    }))
                    setRowerOptions(rowerOptions);  
                });

            }
        });
        }, []);

    const alertClicked = () => {
        alert('You clicked a ListGroupItem');
    };

    return (
        <PageContainer>
            <PageTitle>Rådaregattan 2024</PageTitle>
            
            <Card>
                <SectionTitle>ANMÄL</SectionTitle>
                <RegisterView raceOptions={raceOptions} rowerOptions={rowerOptions}/>
            </Card>

            {loading ? (
                <p>Laddar...</p>
            ) : (
                <Card>
                    <SectionTitle>ANMÄLNINGAR</SectionTitle>
                    <StyledListGroup style={{ border: 'none' }}>
                        {registrations.map((registration) => (
                            <ListGroup.Item 
                                key={registration.id} 
                                action 
                                onClick={() => setSelectedRegistration(registration)}
                                style={{
                                    border: `1px solid ${theme.colors.border}`,
                                    borderRadius: theme.borderRadius.md,
                                    marginBottom: theme.spacing.sm,
                                    transition: 'all 0.2s ease',
                                }}
                                className="registration-item"
                            >
                                <RegistrationView registration={registration} />
                            </ListGroup.Item>
                        ))}
                    </StyledListGroup>
                </Card>
            )}
            
            {selectedRegistration && (
                <RegistrationModal 
                    show={!!selectedRegistration}
                    onHide={() => setSelectedRegistration(null)}
                    registration={selectedRegistration}
                />
            )}
        </PageContainer>
    );
};


export default HomeScreen;